import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils, faUser, faBox, faPhone, faLocation, faMapMarked } from '@fortawesome/free-solid-svg-icons'

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function CustomerSlider() {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        showsPagination={false}
        loop={true}
        autoplay={{
          delay: 2500,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Jas Rai</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-4 text-left">
                “I ordered from here for first time a couple of days ago. This was the best indian food that I have ever ate in the UK ever. The chicken dish and chilli paneer and rotis were 100/100 and the poppadoms were so fresh as well as the somasas. I have ordered indian from 1000s of places in my life but must say I have never enjoyed the food more than I did here.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Google Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Kate Nixon</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-4 text-left">
                “Friendly atmosphere and good food. Happy to come back again in the future.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Google Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Floppymoppy</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-4 text-left">
                “Recently tried out the chartwell arms after being informed that its under new management.
                The food is cheap and good value for what you pay and they have a great selection on the menu.
                The staff are also friendly and helpful. Has a big car park and a lovely relaxing atmosphere.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Tripadvisor Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Sean Regan</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-4 text-left">
                “Ordered through website and food was delivered when they said it would and was to a good standard. All the food ordered was full of flavour. Good selection from the menu including the usual suspects and others not in some establishments.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Google Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Paul Vanags</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                “Best Indian on the Cowley Road these days. Lovely friendly service and great food with a wide variety of options including all the classics. Top banana.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Google Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Ema Murphy</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                “Really good restaurant.
                We had a really nice evening at the Majliss, celebrating my daughters birthday. The food was good, good portions and the bill was reasonable which makes a change! The staff were nice and accommodating.”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Trip Advisor Review
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex md:h-auto flex-col lg:flex-row text-left m-0 sm:m-8 lg:m-16 pb-8 lg:pb-0 drop-shadow-2xl  bg-white border border-white-600  transition ease-in-out delay-50'">
            <div className="mx-16 flex flex-col align-center justify-center flex-1">
              <div className="flex flex-row">
                <div className="flex flex-1 flex-row gap-2 items-center pt-6">
                  <FontAwesomeIcon icon={faUser} color="#ec4899" size="2x" />
                  <div className="font-bold text-base md:text-2xl">Anastasia Singh</div>
                </div>
              </div>
              <div className="text-sm md:text-base lg:text-lg my-8 text-left">
                “This evening's experience at Majliss on the Cowley Road has been absolutely exceptional. The food was absolutely delightful, the service and atmosphere stunning. The spice level in all of our dishes was perfect too. We will be coming back soon!”
              </div>
              <div className="text-sm md:text-base lg:text-lg mb-8 text-left font-bold">
                - Trip Advisor Review
              </div>
            </div>
          </div>
        </SwiperSlide> */}

      </Swiper>
    </div>
  );
}
