import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils, faMapSigns, faBox } from "@fortawesome/free-solid-svg-icons";

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function SliderCards() {
	return (
		<div>
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				showsPagination={false}
				loop={true}
				autoplay={{
					delay: 2500
				}}
				// pagination={{
				//   clickable: true,
				// }}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="mySwiper">
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g1.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/9.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g2.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/3.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g3.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/2.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g4.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/4.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g5.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/5.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g6.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/6.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g7.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/7.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g8.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/8.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						{/* <img className="w-full h-auto  object-contain" src={require("../images/slider/g9.jpg")} alt="..." /> */}
						<img className="w-full h-auto  object-contain" src={require("../images/slider/chartwell/1.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				{/* <SwiperSlide>
					<div className="h-auto ">
						<img className="w-full h-auto  object-contain" src={require("../images/slider/h1.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						<img className="w-full h-auto  object-contain" src={require("../images/slider/h2.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						<img className="w-full h-auto  object-contain" src={require("../images/slider/h3.jpg")} alt="..." />
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="h-auto ">
						<img className="w-full h-auto  object-contain" src={require("../images/slider/h4.jpg")} alt="..." />
					</div>
				</SwiperSlide> */}
			</Swiper>
		</div>
	);
}
