// import logo from './logo.svg';
import React, { useRef, useEffect } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faTruckFast, faClock } from "@fortawesome/free-solid-svg-icons";
import SliderCards from "../components/Slider";
import CustomerSlider from "../components/customer";
import ReactGA from "react-ga";

const TRACKING_ID = "UA-151006169-1";
ReactGA.initialize(TRACKING_ID);

export function Home() {

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	}, []);


	return (

		(
			<div className="App">
				{/* first Section */}
				{/* -------///------- */}
				<div>
					<div className="bg-[url('https://c1.staticflickr.com/5/4229/35129117135_dac33a3014_b.jpg')] h-screen w-full bg-cover no-repeat bg-blend-lighten">

						<div class="w-full h-full backdrop-brightness-25">

							<div className="flex flex-row h-6 pt-8 px-4 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8 ">
								<div className="flex-1">
									<img className="w-36" alt="majliss.." src="https://storage.quickfood.io/logos/m2.png" />
								</div>
								<div>
									<a href="https://order.majliss.co.uk" className="bg-pink-500 hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">
										Order Online
									</a>
								</div>

								{/* <div className="basis-1/2">03</div> */}
							</div>
							<div className="flex flex-col w-100 md:w-2/5 m-auto mt-72 px-4">
								<div className="text-center font-sm text-3xl md:text-5xl">
									<div class="font-medium text-white">Indian restaurant and takeaway</div>
								</div>
								<div className="text-center text-md py-8 text-gray-200">
									The finest ingredients, the skill, imagination and passion of the chefs all come together to craft authentic, timeless and true classics that weave wonders on the taste buds
									and stir the soul.
								</div>
								<div>
									<div className="text-center font-sm text-3xl md:text-5xl">
										<div className="text-pink-500 font-bold">20% Off</div>
									</div>
									<div className="text-center text-md py-2 text-gray-200">Your first order from our website.</div>
									<div className="py-4">
										<a href="https://order.majliss.co.uk" className="bg-pink-500  hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">
											Order Online
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#F3F4F6" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg> */}
				{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
					<path
						fill="#e0dcca"
						fill-opacity="1"
						d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
				</svg> */}

				{/* end first Section */}
				{/* -------///------- */}

				{/* second Section */}
				{/* -------///------- */}
				<div style={{ background: "#e0dcca" }} className="pb-80 pt-20 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8">
					<div className="text-center font-sm text-3xl md:text-5xl">
						<div className="text-xl md:text-3xl font-bold">Opening Hours</div>
					</div>
					<div className="text-center font-sm text-md">
						<div class="font-xl py-3">Sunday 17:30 to 21:30</div>
						<div class="font-xl py-3">Monday 17:30 to 21:30</div>
						<div class="font-xl py-3">Tuesday 17:30 to 21:30</div>
						<div class="font-xl py-3">Wednesday 17:30 to 21:30</div>
						<div class="font-xl py-3">Thursday 17:30 to 21:30</div>
						<div class="font-xl py-3">Friday 17:30 to 22:00</div>
						<div class="font-large py-3">Saturday 17:30 to 22:00</div>
					</div>
					<div className="text-center font-sm text-3xl md:text-5xl pt-8">
						<div className="text-xl md:text-3xl font-bold">Address</div>
					</div>
					<div className="text-center font-sm text-md">
						<div class="font-xl py-3">110 Cowley Rd, Oxford OX4 1JE, United Kingdom</div>
					</div>
					{/* <div className="text-center font-sm text-3xl md:text-5xl pt-8">
						<div className="text-xl md:text-3xl font-bold">Phone</div>
					</div>
					<div className="text-center font-sm text-md">
						<div class="font-xl py-3">01865726728</div>
					</div> */}
				</div>
				<div style={{ marginTop: "-320px" }}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
						<path
							fill="#fff"
							fill-opacity="1"
							d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
					</svg>

					{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#fff" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg> */}
				</div>
				{/* end second Section */}
				{/* -------///------- */}

				{/* third Section */}
				{/* -------///------- */}
				<div className="bg-white pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8">
					<div className="text-center text-3xl md:text-5xl font-bold mb-8"> We care for our Customers</div>
					<div className="flex flex-col lg:flex-row text-left drop-shadow-2xl gap-4 bg-white border border-white-600 m-8 px-8 lg:px-24 py-10 lg:py-32transition ease-in-out delay-50'">
						<div className="flex flex-col flex-1 mb-8 lg:mb-0">
							<div className="text-xl md:text-3xl font-bold">Halal</div>
							<div className="px-4 md:px-8 pb-6 bg-white mb-8">
								<img className="w-96 m-auto h-auto" src={require("../images/halal_small_green.png")} alt="..." />
							</div>
							<div className="text-lg md:text-xl mb-8 text-left">
								We want to make sure we cater for everyone. To achieve this, our meat is Halal. Everyone can enjoy our food, safely knowing how their food is sourced.
							</div>
						</div>
						<div className="flex flex-col flex-1 mb-8 lg:mb-0">
							<div className="text-xl md:text-3xl font-bold">Discounts</div>
							<div className="text-3xl md:text-8xl py-4 font-bold text-pink-500">20% off</div>
							<div className="text-lg md:text-xl my-2 text-left">Your first order from our website.</div>
							<div className="text-lg md:text-xl my-2 text-left text-gray-500">NB: The discount will be auto applied to qualifying orders on checkout</div>
							<div className="my-4">
								<a href="https://order.majliss.co.uk" className="bg-pink-500 hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">
									Order Online
								</a>
							</div>
						</div>
					</div>
					<div className="text-center pt-12 md:pt-24 pt-8 md:pb-16 text-3xl md:text-5xl font-bold mb-8 px-4"> Delivery Info</div>
					<div className="flex flex-col lg:flex-row">
						<div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:cursor-pointer hover:border-pink-500 hover:drop-shadow-2xl hover:-translate-y-1 hover:scale-120'">
							<div className="flex flex-row lg:flex-col items-start">
								<FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faTruckFast} color="#ec4899" size="2x" />
								<a className=" decoration-green-500 font-medium text-xl md:text-2xl">Delivery Schedule</a>
							</div>
							<div className="text-lg md:text-xl my-4 md:my-8 text-left">
								We offer home delivery 7 days a week from 5:30PM each day.
							</div>
							<div className="my-4">
								<a className="bg-pink-500 hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">Order Online</a>
							</div>
						</div>
						<div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:cursor-pointer hover:border-pink-500 hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
							<div className="flex flex-row lg:flex-col items-start">
								<FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faMap} color="#ec4899" size="2x" />
								<a className="decoration-green-500 font-medium text-xl md:text-2xl">Delivery Areas</a>
							</div>
							<div className="text-lg md:text-xl my-4 md:my-8 text-left">Free home delivery within a 3 mile radius.</div>
							<div className="my-4">
								<a href="https://order.majliss.co.uk" className="bg-pink-500 hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">
									Order Online
								</a>
							</div>
						</div>
						<div className="flex-1 text-left border border-white-600 m-8 px-8 py-8 transition ease-in-out delay-50 bg-gradient-to-r from-white to-white hover:cursor-pointer hover:border-pink-500 hover:drop-shadow-2xl hover:-translate-y-1  hover:scale-120'">
							<div className="flex flex-row lg:flex-col items-start">
								<FontAwesomeIcon className="block mb-4 md:mb-8 mr-4" icon={faClock} color="#ec4899" size="2x" />
								<a className=" decoration-green-500 font-medium text-xl md:text-2xl">Delivery Timings</a>
							</div>
							<div className="text-lg md:text-xl my-4 md:my-8 text-left">
								Average delivery time is 25-45 mins. We will confirm expected delivery time upon receiving your order.
							</div>
							<div className="my-4">
								<a href="https://order.majliss.co.uk" className="bg-pink-500 hover:bg-pink-400 text-white font-bold py-2 px-4 cursor-pointer">
									Order Online
								</a>
							</div>
						</div>
					</div>
				</div>
				{/* third Section end */}
				{/* -------///------- */}

				{/* fourth Section */}
				{/* -------///------- */}
				<div className="text-center pt-12 md:pt-24 pt-8 md:pb-16 text-3xl md:text-5xl font-bold mb-8 px-4"> Gallery </div>
				<div className="lg:pb-56 pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 md:pb-36 sm:px-8 sm:pb-32">
					<SliderCards />
				</div>
				{/* <div className='-mt-40 lg:-mt-80'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240"><path fill="#fff" fill-opacity="1" d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            </div> */}

				{/* fourth Section end */}
				{/* -------///------- */}

				{/* fifth Section */}
				{/* -------///------- */}

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 240">
					<path
						fill="#e0dcca"
						fill-opacity="1"
						d="M0,160L30,149.3C60,139,120,117,180,101.3C240,85,300,75,360,85.3C420,96,480,128,540,149.3C600,171,660,181,720,160C780,139,840,85,900,85.3C960,85,1020,139,1080,181.3C1140,224,1200,256,1260,224C1320,192,1380,96,1410,48L1440,0L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
				</svg>
				{/* end fifth Section */}
				{/* -------///------- */}

				{/*sixth Section */}
				{/* -------///------- */}
				<div style={{ background: "#e0dcca" }} className="pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8">
					<div className="text-center text-3xl md:text-5xl font-bold mb-8"> Customer Reviews</div>
					<CustomerSlider />
				</div>
				{/*end sixth Section */}
				{/* -------///------- */}

				{/*seventh Section */}
				{/* -------///------- */}
				<div style={{ background: "#e0dcca" }} className="pt-8 2xl:px-48 xl:24 lg:px-16 md:px-12 sm:px-8">
					<div className="text-center text-3xl md:text-5xl font-bold mb-8"> Follow Us</div>
					<div className="text-lg md:text-xl px-8 my-4 md:my-8 text-left">
						Make sure you follow us on social media for exclusive offers & updates. Also, if you have enjoyed a meal from us, we'd really appreciate it if you wrote us a nice review, it
						really does make a huge difference.
					</div>
					<div className="flex flex-row flex-wrap justify-center items-center">
						<a href="https://www.facebook.com/MajlissOxford" target="_blank" className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50" rel="noreferrer">
							<img className="w-28 h-28 mx-auto " src={require("../images/social/facebook.png")} alt="..." />
						</a>
						<a
							href="https://www.instagram.com/majlissindian/?hl=en"
							target="_blank"
							className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50" rel="noreferrer">
							<img className="w-28 h-28 mx-auto" src={require("../images/social/instagram.png")} alt="..." />
						</a>
						<a
							href="https://www.tripadvisor.com/Restaurant_Review-g186361-d2337093-Reviews-or15-Majliss-Oxford_Oxfordshire_England.html"
							target="_blank"
							className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50" rel="noreferrer">
							<img className="w-28 h-28 mx-auto" src={require("../images/social/tripadvisor.png")} alt="..." />
						</a>

						<a
							href="https://www.google.com/maps/place/Majliss/@51.7399955,-1.2320341,14.21z/data=!4m16!1m9!3m8!1s0x4876c14e6251bc11:0x9c236ba2bdc78cb8!2sMajliss!8m2!3d51.7483391!4d-1.2390073!9m1!1b1!16s%2Fg%2F1trxd5_p!3m5!1s0x4876c14e6251bc11:0x9c236ba2bdc78cb8!8m2!3d51.7483391!4d-1.2390073!16s%2Fg%2F1trxd5_p?entry=ttu"
							target="_blank"
							className="flex-none lg:flex-1 text-left m-4 md:m-8 py-0 md:py-8 transition ease-in-out delay-50" rel="noreferrer">
							<img className="w-28 h-28 mx-auto" src={require("../images/social/google.png")} alt="..." />
						</a>
					</div>
				</div>
				{/*end seventh Section */}
				{/* -------///------- */}

				{/*Footer Section */}
				{/* -------///------- */}
				<div className="bg-white-100 py-8 2xl:px-48 xl:px-24 lg:px-16 md:px-12 sm:px-8 flex flex-col sm:flex-row align-start justify-start">
					{/* <div className='flex-1 flex flex-col flex-row items-center'>
                    <div className='mx-8 text-sm cursor-pointer'>
                        <Link to="/privacy">Privacy Policy </Link></div>
                </div> */}
					<div className="flex-1 text-md items-center">
						© 2023 Developed by <span className="text-green-600 font-bold"> <a href="https://quickfood.io/" target="_blank" rel="noreferrer">QuickFood.</a> </span>All Rights Reserved.
					</div>
					{/* <div className='flex-1 flex flex-col flex-row items-center sm:items-end'>
                    <div className='text-sm cursor-pointer'>
                        <Link to="/terms">Terms & Condition</Link>
                    </div>

                </div> */}
				</div>
				{/*End Footer Section */}
				{/* -------///------- */}
			</div>
		)
	);
}
